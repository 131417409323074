<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input
          label="话题标题:"
          v-model="searchParam.topicTitle"
          placeholder="请输入话题标题"
        />
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.pointCount == 0"
          text="发放积分"
          type="text"
          @click="distributePoints(scope.row)"
        />
        <v-button v-else type="text" disabled>{{
          "已发放" + scope.row.pointCount + "积分"
        }}</v-button>
        <v-button
          text="删除"
          disable
          type="text"
          @click="deleteTopic(scope.row)"
        />
      </template>
    </v-list>
    <!-- <el-dialog :visible.sync="isdialog" width="40%">
        <el-form label-position="right" label-width="150px">
            <el-form-item label="发放积分数目：">
                <v-input clearable class="filter-item" v-model="points"></v-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialogBottomBtn">
            <v-button size="medium" @click="submit()" text="保存"></v-button>
            <v-button size="medium" @click="isdialog = false" text="取消"></v-button>
        </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { getTopicListURL, deleteTopicUrl, doTopicURL } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "residentTopicList",
  data() {
    return {
      points: null,
      nowId: null,
      //
      userId: null,
      tenant: null,
      communityId: null,
      roomId: null,

      isdialog: false,
      searchParam: {
        communityId: null,
        topicTitle: "",
        topicType: 15,
        createTimeS: "",
        createTimeE: "",
        sortField: "NEW",
        needContentFlag: 1,
        // tenantId: this.$store.state.app.userInfo.tenantId, // 固定写死为“荷蜂社区”
      },
      tableUrl: getTopicListURL,
      headers: [
        {
          prop: "topicPicture",
          label: "话题封面图",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "topicTitle",
          label: "话题标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "topicContent",
          label: "话题内容",
        },
        {
          prop: "praiseCount",
          label: "点赞数目",
        },
        {
          prop: "commentCount",
          label: "评论数目",
        },
        {
          prop: "readCount",
          label: "浏览数目",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  created() {
    console.log(this.$store.state.app.userInfo);
  },
  methods: {
    distributePoints(item) {
      let data = [
        {
          userId: item.createUser,
          tenant: item.tenantId,
          communityId: item.communityId || undefined,
          // roomId: this.$store.state.app.userInfo.roomId || undefined,
        },
      ];
      this.$confirm("是否发放积分?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${doTopicURL}`, {
            data,
            relationId: item.id,
          })
          .then((res) => {
            if (res.code === 200) {
              this.points = null;
              this.$message.success("操作成功");
              this.isdialog = false;
              this.$refs.list.search();
            }
          });
      });
    },
    // submit() {
    //     let data1 = {
    //         userId: this.userId,
    //         tenant: this.tenant,
    //         communityId: this.communityId || undefined,
    //         // roomId: this.$store.state.app.userInfo.roomId || undefined,
    //     }
    //     let data = []
    //     data.push(data1)
    //     this.$confirm("是否发放积分?", "提示", {
    //         confirmButtonText: "确定",
    //         cancelButtonText: "取消",
    //         type: "warning",
    //     }).then(() => {
    //         this.$axios
    //             .post(`${doTopicURL}`, {
    //                 data,
    //                 relationId: this.nowId,
    //                 pointCount: Number(this.points),
    //             })
    //             .then((res) => {
    //                 if (res.code === 200) {
    //                     this.points = null
    //                     this.$message.success("操作成功");
    //                     this.isdialog = false
    //                     this.$refs.list.search();
    //                 }
    //             });
    //     });
    // },
    deleteTopic(item) {
      //删除居民超话
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
